<template>
  <div>
    <div class="page-background">
      <div>
        <div>
          <p class="companyName">广州景唐科技有限公司</p>
        </div>
        <div>
          <p class="briefIntroduction">
            广州景唐科技有限公司成立于2023年，致力于为全球用户提供极致的移动互联网体验。公司打造<br>
            以图形图像、效率工具、运动健康为主的移动产品矩阵，深受全球用户的喜爱。
          </p>
        </div>
      </div>

      <!--    <p class="briefIntroduction2">以图形图像、效率工具、运动健康为主的移动产品矩阵，深受全球用户的喜爱。</p>-->
      <div class="briefIntroduction3">
        <!--      <p class="briefIntroduction4">ABOUT US</p>-->
        <!--      <p class="briefIntroduction5">公司拥有一流的移动互联网产品研发团队，成功创造出多款千万级用户量的移动互联网产品。公司经过两年多的高速发展，已经打造出以图形图像、运动健康、生活应用为主的移动产品矩阵，累计用户超过五千<br>万，覆盖全球100多个国家和地区，深受全球用户的喜爱，成为在产品设计、技术研发及商业化运营上都非常有竞争力的移动互联网公司。</p>-->
      </div>

      <!--    <div class="juse">-->
      <!--      <div class="MyInfo">-->
      <!--        <div>-->
      <!--          <img src="../assets/logo.jpg" class="logo">-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          <p class="productInfo">漫影相机</p>-->
      <!--          <p class="productInfo2">【漫影相机】个性化你的照片。【漫艺术效果】丰富多彩的卡通、素描、艺术风格效果任你选！【挡脸特效】贴心保护隐私又有无限趣味<br>玩法。【百变发型】各种炫酷时尚的发型，在线实时体验。【趣味贴纸】萌趣表情 & 鬼马自拍的小秘诀。【多彩滤镜】百变风格质感滤<br>镜，可盐可甜。【炫彩效果】一键get，BlingBling炫彩特效加持仙气值。</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="NoteInfo">-->
      <!--        <div>-->
      <!--          <img src="../assets/logo.jpg" class="logo">-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          <p class="productInfo">漫影相机</p>-->
      <!--          <p class="productInfo2">【漫影相机】个性化你的照片。【漫艺术效果】丰富多彩的卡通、素描、艺术风格效果任你选！【挡脸特效】贴心保护隐私又有无限趣味<br>玩法。【百变发型】各种炫酷时尚的发型，在线实时体验。【趣味贴纸】萌趣表情 & 鬼马自拍的小秘诀。【多彩滤镜】百变风格质感滤<br>镜，可盐可甜。【炫彩效果】一键get，BlingBling炫彩特效加持仙气值。</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="EPInfo">-->
      <!--        <div>-->
      <!--          <img src="../assets/logo.jpg" class="logo">-->
      <!--        </div>-->
      <!--        <div>-->
      <!--          <p class="productInfo">漫影相机</p>-->
      <!--          <p class="productInfo2">【漫影相机】个性化你的照片。【漫艺术效果】丰富多彩的卡通、素描、艺术风格效果任你选！【挡脸特效】贴心保护隐私又有无限趣味<br>玩法。【百变发型】各种炫酷时尚的发型，在线实时体验。【趣味贴纸】萌趣表情 & 鬼马自拍的小秘诀。【多彩滤镜】百变风格质感滤<br>镜，可盐可甜。【炫彩效果】一键get，BlingBling炫彩特效加持仙气值。</p>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--    </div>-->
      <!--    <div class="image-container">-->
      <!--      <img v-for= "item in images" :key = "item.id" :src="item.imgSrc" style="width: 14%;height: 14%;margin-left: 10px">-->
      <!--    </div>-->
      <!--      <el-carousel :interval="4000" type="card" height="400px">-->
      <!--        <el-carousel-item v-for="item in images" :key="item.id">-->
      <!--          <div class="carousel-item-container">-->
      <!--            <div class="leftBig">-->
      <!--              <div class="carousel-item-description">-->
      <!--                <img :src="item.imgSrc" style="width: 100px;height: 100px">-->
      <!--              </div>-->
      <!--              <div>-->
      <!--                <p class="appName">appName</p>-->
      <!--              </div>-->
      <!--              <div>-->
      <!--                我是app介绍-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="carousel-item-image">-->
      <!--              <img :src="item.imgSrc">-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </el-carousel-item>-->
      <!--      </el-carousel>-->
      <!--    <div class="block">-->
      <!--    <p class="demonstration">公司产品</p>-->
      <!--    <el-carousel :interval="4000" type="card" height="500px">-->
      <!--        <el-carousel-item v-for="item in images" :key="item.id" style="box-shadow: 0 0 3px 0 #ccc;">-->
      <!--          <div class="carousel-item-container">-->
      <!--            <div class="leftBig">-->
      <!--              &lt;!&ndash; 图片和应用名称 &ndash;&gt;-->
      <!--              <div class="carousel-item-description">-->
      <!--                <img :src="item.imgSrc" class="carousel-item-thumb">-->
      <!--                <div>-->
      <!--                  <p style="text-align: center;font-weight: bold">{{item.appName}}</p>-->
      <!--                  <p class="appName">{{item.descInfo}}</p>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              &lt;!&ndash; app介绍 &ndash;&gt;-->
      <!--&lt;!&ndash;              <div class="app-description">&ndash;&gt;-->
      <!--&lt;!&ndash;                我是app介绍&ndash;&gt;-->
      <!--&lt;!&ndash;              </div>&ndash;&gt;-->
      <!--            </div>-->
      <!--            <div class="carousel-item-image">-->
      <!--              <img :src="item.imgSrc" class="carousel-item-big-image">-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </el-carousel-item>-->
      <!--      </el-carousel>-->
      <!--    </div>-->
    </div>
    <div class="MyDiv">
      <div class="MyDivLeft">
        <div class="MyLogoAndName">
          <div class="MyLogo">
            <img src="../assets/logo.jpg" class="MyLogoImg">
          </div>
          <div class="MyAppName">
            <p class="MyAppNameP">漫影相机</p>
            <p class="MyAppNameP1">漫影相机</p>
          </div>
        </div>
        <div class="MyIntroduce">
          <p class="MyIntroducep">【漫影相机】个性化你的照片。【漫艺术效果】丰富多彩的卡通、素描、艺术风格效果任你选！【挡脸特效】贴心保护隐私又有无限趣味<br>玩法。【百变发型】各种炫酷时尚的发型，在线实时体验。【趣味贴纸】萌趣表情 & 鬼马自拍的小秘诀。【多彩滤镜】百变风格质感滤<br>镜，可盐可甜。【炫彩效果】一键get，BlingBling炫彩特效加持仙气值。</p>
        </div>
      </div>
      <div class="MyDivRight">
        <img src="../assets/store1.jpg" class="MyDivRightImg">
      </div>
    </div>
<!--    EP相册-->
    <div class="MyDiv">
      <div class="MyDivLeft">
        <div class="MyLogoAndName">
          <div class="MyLogo">
            <img src="../assets/EPLogo.jpg" class="MyLogoImg">
          </div>
          <div class="MyAppName">
            <p class="MyAppNameP">EP</p>
            <p class="MyAppNameP1">EP</p>
          </div>
        </div>
        <div class="MyIntroduce">
          <p class="MyIntroducep">EP 是一款相册、应用隐私保护软件，可以将桌面图标伪装成电话、计算器等样式，您的各类图片、视频、各种应用都可以添加至EP内，打造个性化的隐藏空间，再也不怕被别人看到!
            【伪装模式】将桌面图标伪装成电话、计算器等样式
            【专属密码】隐私空间密码加密，安心无忧
            【窗口隐藏】多任务窗口时不显示应用页面信息，防止被有心人看到
            【私密相册/视频】支持各类格式，全面保护深度隐藏
            【免费无广告】无打扰纯净版，专注沉浸式体验</p>
        </div>
      </div>
      <div class="MyDivRight">
        <img src="../assets/EPStore.png" class="MyDivRightImg">
      </div>
    </div>

    <!--    随意记-->
    <div class="MyDiv">
      <div class="MyDivLeft">
        <div class="MyLogoAndName">
          <div class="MyLogo">
            <img src="../assets/noteLogo.png" class="MyLogoImg">
          </div>
          <div class="MyAppName">
            <p class="MyAppNameP">随意记</p>
            <p class="MyAppNameP1">随意记</p>
          </div>
        </div>
        <div class="MyIntroduce">
          <p class="MyIntroducep">随易记，随时随地轻松记笔记、写便签、列待办事项和购物清单等。
            <br>1、下拉即可创建记事和待办
            <br>2、3种不同的浏览模式
            <br>3、使用标签进行管理
            <br>4、多样化便签主题图案
            <br>5、置顶重要便签并通过桌面小组件查看</p>
        </div>
      </div>
      <div class="MyDivRight">
        <img src="../assets/noteStore.png" class="MyDivRightImg">
      </div>
    </div>

    <!--    随意记-->
    <div class="MyDiv">
      <div class="MyDivLeft">
        <div class="MyLogoAndName">
          <div class="MyLogo">
            <img src="../assets/question.png" class="MyLogoImg">
          </div>
          <div class="MyAppName">
            <p class="MyAppNameP">智趣快答</p>
            <p class="MyAppNameP1">智趣快答</p>
          </div>
        </div>
        <div class="MyIntroduce">
          <p class="MyIntroducep">智趣快答是一款休闲答题应用，题目类型丰富多彩，涵盖常识、
            历史、地理等多个领域，通过答题增加了趣味性，快来挑战吧，让我们在欢乐时光中学习新知识。</p>
        </div>
      </div>
      <div class="MyDivRight">
        <img src="../assets/questionStore.png" class="MyDivRightImg">
      </div>
    </div>

    <div class="buttonDiv">
      <p class="companyName2">广州景唐科技有限公司</p>
      <p class="companyName3"><a href="https://beian.miit.gov.cn/?spm=5176.beian-pc.icp_beian.14.f0d079feragy9i#/Integrated/index" style="text-decoration: none;color: #6565c6;font-size: 20px">粤ICP备2024233489号-2</a></p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomeView',
  data () {
    return {
      // images: [
      //   './assets/store1.jpg',
      //   './assets/store2.jpg',
      //   './assets/store3.jpg',
      //   './assets/store4.jpg',
      //   './assets/store5.jpg'
      // ]
      // images: [
      //   {
      //     id: 1,
      //     imgSrc: require('../assets/store1.jpg'),
      //     descInfo: '【漫影相机】个性化你的照片。【漫艺术效果】丰富多彩的卡通、素描、艺术风格效果任你选！【挡脸特效】贴心保护隐私又有无限趣味<br>玩法。【百变发型】各种炫酷时尚的发型，在线实时体验。【趣味贴纸】萌趣表情 & 鬼马自拍的小秘诀。【多彩滤镜】百变风格质感滤<br>镜，可盐可甜。【炫彩效果】一键get，BlingBling炫彩特效加持仙气值。',
      //     appName: '漫影相机'
      //   },
      //   {
      //     id: 2,
      //     imgSrc: require('../assets/store2.jpg'),
      //     descInfo: '【漫影相机】个性化你的照片。【漫艺术效果】丰富多彩的卡通、素描、艺术风格效果任你选！【挡脸特效】贴心保护隐私又有无限趣味<br>玩法。【百变发型】各种炫酷时尚的发型，在线实时体验。【趣味贴纸】萌趣表情 & 鬼马自拍的小秘诀。【多彩滤镜】百变风格质感滤<br>镜，可盐可甜。【炫彩效果】一键get，BlingBling炫彩特效加持仙气值。',
      //     appName: '便签'
      //   },
      //   {
      //     id: 3,
      //     imgSrc: require('../assets/store3.jpg'),
      //     descInfo: '【漫影相机】个性化你的照片。【漫艺术效果】丰富多彩的卡通、素描、艺术风格效果任你选！【挡脸特效】贴心保护隐私又有无限趣味<br>玩法。【百变发型】各种炫酷时尚的发型，在线实时体验。【趣味贴纸】萌趣表情 & 鬼马自拍的小秘诀。【多彩滤镜】百变风格质感滤<br>镜，可盐可甜。【炫彩效果】一键get，BlingBling炫彩特效加持仙气值。',
      //     appName: 'EP相册'
      //   }
      // ]
    }
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.page-background {
  background-image: url('../assets/0000.jpg'); /* 替换为你的图片路径 */
  background-size: cover; /* 背景图片覆盖整个元素 */
  background-position: center; /* 背景图片居中 */
  background-repeat: no-repeat; /* 不重复背景图片 */
  height: 100vh; /* 可以根据需要设置高度 */
  width: 100%; /* 可以根据需要设置宽度 */
  margin-top: -25px;
  overflow-x: auto; /* 水平滚动条 */
  //white-space: nowrap; /* 防止内容换行 */
}
.companyName{
  //position: absolute;
  //top: 3%; /* 根据需要调整 */
  //left: 11%;
  //transform: translate(-50%, -50%); /*水平垂直居中*/
  color: white; /* 根据背景调整文字颜色 */
  font-size: 25px;
}
.briefIntroduction{
  position: absolute;
  top: 80%; /* 根据需要调整 */
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* 根据背景调整文字颜色 */
  //margin-top: 500px;
  //text-align: center;
}
.briefIntroduction2{
  position: absolute;
  top: 83%; /* 根据需要调整 */
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; /* 根据背景调整文字颜色 */
}
.briefIntroduction3{
  //width: 100%;
  //margin-top: 800px;
  //height: 200px;
  //background-color: white;
  //position: absolute;
  //top: 110%; /* 根据需要调整 */
  //left: 50%;
  //transform: translate(-50%, -50%);
  //margin-top: 400px;
  text-align: center;
  //margin-top: 1800px;
}
//.briefIntroduction4{
//  margin-top: 800px;
//  position: absolute;
//  //top: 20%; /* 根据需要调整 */
//  left:50%;
//  //transform: translate(-50%, -50%);
//  font-weight: bold;
//}
.briefIntroduction5{
  //margin-top: 800px;
  position: absolute;
  //left: 25%;
  top: 110%; /* 根据需要调整 */
  //margin-top: 900px;
  padding: 20px;
  //left:50%;
  //transform: translate(-50%, -50%);
  //text-align: center;
  //width: 70%;
}
.juse{
  display: flex;
  justify-content: space-between;
}
.MyInfo{
  width: 25%;
  height: 500px;
  //background-color: red;
  position: absolute;
  top: 130%;
  left: 5%;
  box-shadow: 0 0 3px 0 #ccc;
  text-align: center;
}

.NoteInfo{
  width: 25%;
  height: 500px;
  //background-color: red;
  position: absolute;
  top: 130%;
  left: 37%;
  box-shadow: 0 0 3px 0 #ccc;
  text-align: center;
}
.EPInfo{
  width: 25%;
  height: 500px;
  //background-color: red;
  position: absolute;
  top: 130%;
  left: 69%;
  box-shadow: 0 0 3px 0 #ccc;
  text-align: center;
}
.logo{
  //position: absolute;
  //top: 160%; /* logo 相对于父容器顶部的偏移量 */
  //left: 50%; /* logo 相对于父容器左侧的偏移量 */
  //transform: translate(-50%, 20%); /* 将 logo 水平和垂直居中 */
  width: 100px; /* logo 图片的宽度 */
  height: 100px; /* logo 图片的高度 */
  display: inline-block;
}
.productInfo{
  //position: absolute;
  //top: 150%; /* logo 相对于父容器顶部的偏移量 */
  //left: 55%; /* logo 相对于父容器左侧的偏移量 */
  font-weight: bold;
  font-size: 25px;
}
.productInfo2{
  //position: absolute;
  //top: 165%; /* logo 相对于父容器顶部的偏移量 */
  //left: 36%; /* logo 相对于父容器左侧的偏移量 */
}
.image-container {
  display: flex; /* 使用 flex 布局 */
  position: absolute;
  //top:180%;
  justify-content: center; /* 将项目水平居中对齐 */
  top:230%;
  //left: 10%;
  //transform: translate(-50%, -50%);
}
.buttonDiv{
  width: 100%;
  height: 200px;
  background-color: black;
  display: flex;
  flex-direction: column;
  //position: absolute;
  //top: 270%; /* 根据需要调整 */
  //left: 50%;
  //transform: translate(-50%, -50%);
}
.companyName2{
  color: white;
  text-align: center;
  //position: absolute;
  //left: 50%;
  //transform: translate(-50%, -50%);
  font-size: 18px;
  margin-top: 40px;
}
.companyName3{
  //position: absolute;
  //top: 44%; /* 根据需要调整 */
  //left: 50%;
  //transform: translate(-50%, -50%);
  color: #6565c6;
  text-align: center;
}

//.el-carousel__item h3 {
//  color: #ffffff;
//  font-size: 14px;
//  opacity: 0.75;
//  line-height: 200px;
//  margin: 0;
//}
//
//.el-carousel__item:nth-child(2n) {
//  background-color: #ffffff;
//}
//
//.el-carousel__item:nth-child(2n+1) {
//  background-color: #ffffff;
//}
//.carousel-item-container {
//  display: flex; /* 使用flex布局 */
//  justify-content: space-between; /* 子元素之间的空间分布 */
//  //align-items: center; /* 子元素垂直居中 */
//  height: 100%; /* 继承外层容器的高度 */
//  padding: 20px; /* 可选：添加内边距 */
//}
//
//.carousel-item-description {
//  flex: 1; /* 占据可用空间的1份 */
//  //margin-right: 100px; /* 可选：右侧外边距 */
//}
//.leftBig{
//  display: flex; /* 使用flex布局 */
//  justify-content: space-between; /* 子元素之间的空间分布 */
//}
//.carousel-item-image img {
//  max-width: 50%; /* 限制图片最大宽度为容器的一半 */
//  height: auto; /* 图片高度自动，保持比例 */
//  margin-left: 180px;
//}
//.block{
//  width: 80%;
//  position: absolute;
//  top:150%; /* 根据需要调整 */
//  left: 50%;
//  transform: translate(-50%, -50%);
//}
//.carousel-item-container {
//  display: flex;
//  justify-content: space-between;
//  height: 100%;
//  padding: 20px;
//}
//
//.leftBig {
//  display: flex;
//  //flex-direction: column; /* 改为列方向布局 */
//  //align-items: flex-start; /* 子元素垂直对齐 */
//  //width: 100%; /* 确保宽度占满 */
//  justify-content: space-between; /* appName和图片之间留有空隙 */
//  //align-items: center; /* 垂直居中 */
//}
//
//.carousel-item-description {
//  display: flex;
//  flex-direction: column; /* 改为列方向布局 */
//  align-items: flex-start; /* 垂直对齐 */
//  width:200px;
//  margin-left: 50px;
//}
//
//.carousel-item-thumb {
//  width: 100px;
//  height: 100px;
//  margin-bottom: 10px; /* 图片下面留一点空间 */
//  margin-left: 25%;
//}
//
//.appName {
//  /* 这里可以添加appName的样式 */
//}
//
//.app-description {
//  /* 这里可以添加app介绍的样式 */
//  margin-top: 10px; /* 与上面的元素保持一点间距 */
//  width: 100px
//}
//
//.carousel-item-image img {
//  max-width: 80%;
//  height: 80%;
//  //margin-left: 40px; /* 让图片靠右对齐 */
//  margin-top: 10%;
//}
//.demonstration{
//  font-size: 30px;
//  font-weight: bold;
//  text-align: center;
//  margin-top: 18%;
//}
.MyDiv{
  width: 100%;
  height: 100vh;
  //background-color: red;
  display: flex;
}
.MyDivLeft{
  width: 50%;
  height: 100vh;
  //background-color: #6565c6;
  margin-left: 100px;
}
.MyDivRight{
  width: 50%;
  height: 100vh;
  //background-color: #545454;
}
.MyLogoAndName{
  height: 40%;
  width: 100%;
  //background-color: green;
  display: flex;
}
.MyIntroduce{
  height: 60%;
  width: 100%;
  //background-color: seashell;
}
.MyLogo{
  height: 100%;
  width: 30%;
  //background-color: red;
}
.MyAppName{
  height: 100%;
  width: 70%;
  //background-color: #6565c6;
}
.MyLogoImg{
  width: 65%;
  height: 50%;
  margin-top: 30%;
  margin-left: 30%;
}
.MyAppNameP{
  font-size: 60px;
  font-weight: bold;
  margin-left: 10%;
  margin-top: 15%;
}
.MyAppNameP1{
  font-size: 30px;
  margin-left: 10%;
  margin-top: -10%;
  color: #707987;
}
.MyIntroducep{
  font-size: 20px;
  width: 80%;
  margin-left: 10%;
}
.MyDivRightImg{
  width: 42%;
  height: 80%;
  margin-top: 10%;
  margin-left: 20%;
}
</style>
